import React from 'react'

const Aboutus = () => {
  return (
    <>
    <section className="inner_about">
    <div className="container">
        <div className="inner_about_area">
            <h2>Get hooked to binge-worthy content tailored to your taste in your language!</h2>
            <h3>Chaska is a video on demand streaming service that offers a wide variety of genres from drama, horror, suspense, thriller to comedy &  beyond. Binge watch from our collection of web series, movies, Chaska Originals and more in your regional language.Enjoy unlimited video streaming and downloads at a pocket friendly price all year long.</h3>
            <h3>Address : 158/1259A, 1st Floor, Rd Number 3, Sahayog CHS LTD, Prarthana Society, Motilal Nagar I, Goregaon West, Mumbai, Maharashtra 400104</h3>
            <ul>
                <li>Flip through trailers to help choose what to watch first.</li>
                <li>Personalized content according to your geographical location.</li>
                <li>Unlimited HD streaming and downloading 24x7.</li>
                <li>Personalize your membership plan to suit your need.</li>
                <li>Access content anywhere in the world.</li>
                <li>Download videos for offline viewing-on-the-go.</li>
                <li>Cast to TV.</li>
            </ul>
        </div>
    </div>
</section>
    </>
  )
}

export default Aboutus