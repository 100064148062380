import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'
import { loadReCaptcha } from 'react-recaptcha-v3'
class ExampleComponent extends Component {
    
    componentDidMount() {
        loadReCaptcha('6LeJ3AkhAAAAACTTuZDpMWPG4lavQMoGwRhfcx15', console.log('loading'));
      }

      
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    console.log(recaptchaToken, "<= your recaptcha token")
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }
  render() {
    return (
      <div>
        <ReCaptcha
            ref={ref => this.recaptcha = ref}
            sitekey="6LeJ3AkhAAAAACTTuZDpMWPG4lavQMoGwRhfcx15"
            action='action_name'
            verifyCallback={this.verifyCallback}
        />

      </div>
    );
  };
};

export default ExampleComponent;