import { analytics}  from "../firebase";
import { logEvent } from "firebase/analytics";

const upcoming_trailer =(title)=>{
    logEvent(analytics,'upcoming_trailer',{client:'web' , name:title})
}
const deep_link=(title)=>{
    logEvent(analytics,'deep_link',{client:'web', name:title})
}
const click_poster=(title)=>{
    logEvent(analytics,'click_poster',{client:'web', name:title})
}
const checkout_paytm=(price,currency)=>{
    logEvent(analytics,'checkout_paytm',{client:'web', price:price , currency:currency})
}
const checkout_stripe=(price,currency)=>{
    logEvent(analytics,'checkout_stripe',{client:'web', price:price , currency:currency})
}
const begin_checkout=(price,currency)=>{
    logEvent(analytics,'begin_checkout',{client:'web', price:price , currency:currency})
}
const purchase=(orderId,txnid)=>{
    logEvent(analytics,'purchase',{client:'web', orderId:orderId,transaction_id:txnid})
}
const view_item=(title)=>{
    logEvent(analytics,'view_item',{client:'web', title:title})
}
const login=(mode)=>{
    logEvent(analytics,'login',{client:'web', type:mode})
}
const sign_up=(mode)=>{
    logEvent(analytics,'sign_up',{client:'web', type:mode})
}
const watch_trailer=()=>{
    logEvent(analytics,'watch_trailer',{client:'web'})
}
const upgrade_package=()=>{
    logEvent(analytics, 'upgrade_package' ,{client:'web'})
}
const add_to_cart=(name)=>{
    logEvent(analytics, 'add_to_cart' ,{client:'web',name:name})
}
export {upcoming_trailer,deep_link,checkout_paytm,checkout_stripe,
    begin_checkout,purchase,view_item,click_poster,login,sign_up,
    watch_trailer,upgrade_package,add_to_cart}