import React, {useState,useRef}  from 'react'
import facebook from "../assets/image/facebook_icon.png";
import google from "../assets/image/google_icon.png";
import user from "../assets/image/input_field_user.png";
import password from "../assets/image/input_field_password.png";
import logo from '../assets/logo/chaska.png';
import 'react-phone-number-input/style.css';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const Login = () => {
    const {handleFacebookSignIn, login_with_otp , googleSignIn,loginwithemail,forgetpass,verify } = useUserAuth();
    const [userinput,setuser]= useState('');
    const [passwordinput,setpassword] = useState('');
    const [permission,setpermission]=useState('voovi');
    const [otp, setotp]=useState('')
    const [number , setnumber]=useState('')
    const [error,setError]=useState('')
    const [usercheck, setusercheck]= useState(true)
    const [show_msg,setshow_msg]=useState(false)
    const [flag1,setflag1]=useState(false)
    const [flag,setflag]=useState(false)
    const search = new URLSearchParams(window.location.search)
    const message = search.get("message");
    var pattern = new RegExp(/^[0-9\b]+$/);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

     const handleuser =(e)=>{
        // setuser(e.target.value)
        // var ss = e.target.value
        // if(ss.length === 10){
        //   pattern.test(e.target.value) ?   logic(e) : setusercheck(true);
        // }
        //setusercheck(true)
     }
     const logic=(e)=>{
        
        setusercheck(false) 
        setnumber('+91-'+e.target.value)
        setotp('')
        setError('')
     }
     const handlepassword=(e)=>{
        setpassword(e.target.value)
     }
     const handlepassword_otp=(e)=>{
        setotp(e.target.value)
     }

    //validating its email sign in or mobile sign in using input value 
    const login=(e)=>{
        // e.preventDefault()
        // const exp = !!regex.test(userinput)
        // if(userinput === ''){
        //   setshow_msg(true)
        //   setError('please fill the mandatory fields*')
        // }
        // else{
        //   if(usercheck){
        //     if(!exp){
        //       setshow_msg(true)
        //       setError('please enter a valid email*')
        //     }else{
        //       const data = {
        //       email: userinput,
        //       password:passwordinput,
        //       permission:permission
        //     }
        //       loginwithemail(data).then(res => {
        //         if(!res){
        //           setError('something went wrong')
        //         }else{
        //           setError(res.data.message)
        //         }
        //         }) 
        //     }
        //   }else{
        //     loginwithphone()
        //   }
        //   setshow_msg(true)
        //   }
        
    }

  const loginwithphone =async ()=>{
    if(number.length === 14){
      const params = {
        phoneNumber:number,
        platform:'Web'
      }
      const data = await login_with_otp(params)
      setError(data.data.message) 
      if(data.data.code === 0){
        setflag1(true)
      } else{
        setflag1(false)
      } 
    }else{
      setError('please enter valid number') 
    }
    
  }

  const verifyOtp = async (e) => {
    e.preventDefault()
    if (otp === "" || otp === null) return setError("Please enter a valid OTP!");
    else{
      const params = {
        phoneNumber:number,
        platform:'Web',
        otp:otp
      }
      const data = await login_with_otp(params)
      setError(data.data.message)  
    }
  };


 const [check,setcheck]=useState(false)
  const handleforgetpassword=()=>{
    setcheck(!check)
    setuser('')
  }
  const forgetpassword=async (e)=>{
    e.preventDefault()
    const alert = await forgetpass(userinput)
    setError(alert)
    setshow_msg(true)
  }
  
 const handler=async (e)=>{
  if(e === null){
    setflag(false)
    setshow_msg(true)
    setError('recaptcha verfication unsuccessfull, please try agian later.')
  }else{
    const result = await verify(e);
    if(result.data.data.success){
      setflag(true)
      setError('')  
    }
  }
 }
  return (
    <>
    <section className="login">
    <div className="logincontent left">
        <div className="content_area">
            <div className="content_inner_area">
                <a href="/registration" className="login_logo"><img src={logo} alt=""/></a>
                <ul>
                    <li>New web series release every week</li>
                    <li>Unlimited streaming</li>
                    <li>HD + (2k) Qualtiy</li>
                    <li>Get full access to all premium content</li>
                </ul>
                <h3>Dont Have An Account?</h3>
                <div className="flip_btn_area">
                    <a href="/register" className="flip_btn">REGISTER NOW</a>
                </div>
            </div>
        </div>
    </div>
    <div className="login_form right">
        <div className="login_form_area">
            <div className="login_inner_area">
                <a href="/" className="back_home"><span>Back To Home</span></a>
              
                <div className="content_heading">
                  <h2>Sign<span> In</span></h2>
                  
                  { show_msg ? 
                    <p style={{'color':'#ccc','marginTop':'10px'}}>{error}</p>
                  :<p style={{'color':'#ccc','marginTop':'10px'}}>{!message ? '':message}</p>}
                </div>

                <form>
                    <div className="input_field_area">
                      {usercheck ? 
                        <div>
                        {check ? 
                        <div>
                        {/*password reset screen UI*/}
                        <i className="fa fa-arrow-left p-2" style={{'color':'#fff'}} onClick={()=> setcheck(!check)}></i>
                        <div className="input_field_col">
                            <span><img src={user} alt=""/></span>
                            <input required type="email" id="login_email" value={userinput} onChange={(e)=> setuser(e.target.value)} placeholder="Enter your email" />
                            <button className='login' onClick={forgetpassword}>Get Reset Link</button>
                        </div>
                        </div>
                        :
                        <div>
                        <p className="p-2" style={{'color':'#fff'}}><i className="fa fa-arrow-right"></i> <span className='p-2'>Sign In using Email</span></p>
                        <div className="input_field_col">
                            <span><img src={user} alt=""/></span>
                            <input type="email" className='form-control' onChange={handleuser} placeholder="enter your email or mobile number" />
                        </div>
                        
                        <div className="input_field_col">    
                            <span><img src={password} alt=""/></span>
                            <input type="password" className='form-control' onChange={handlepassword} placeholder="enter password *"/>
                        </div> 
                        
                        <div className="input_field_col">
                        <a href="#" className="forgot" onClick={handleforgetpassword}>Forget Password?</a>
                        <ReCAPTCHA
                        className='m-1'
                        sitekey={SITE_KEY}
                        onChange={handler}
                        />
                        {flag ?
                        <button className='login' onClick={login}>LOGIN</button>:
                        <button className='login' disabled onClick={login}>LOGIN</button>
                        }
                        
                        </div>
                        </div>
                          }
                        
                        </div>
                        :
  
                        <div>
                          <p className="p-2" style={{'color':'#fff'}}><i className="fa fa-arrow-left" onClick={()=> setusercheck(!usercheck)}></i> 
                          <span className='p-2'>Sign In using Mobile </span>
                         </p>
                        <div className='input_field_col'>
                        <span><img src={user} alt=""/></span>
                        <input type="text" className='form-control' value={number} onChange={(e)=>setnumber(e.target.value)}  placeholder="Enter phone number*"/>
                        </div>
                        
                        {flag1 ?
                        <div className="input_field_col mt-3">
                          <span>
                          <svg style={{'width':'24px','height':'24px','color':'#fff','marginLeft':'-3px'}} viewBox="0 0 24 24">
                              <path fill="currentColor" d="M19 13C19.34 13 19.67 13.04 20 13.09V10C20 8.9 19.11 8 18 8H17V6C17 3.24 14.76 1 12 1S7 3.24 7 6V8H6C4.9 8 4 8.89 4 10V20C4 21.11 4.89 22 6 22H13.81C13.3 21.12 13 20.1 13 19C13 15.69 15.69 13 19 13M9 6C9 4.34 10.34 3 12 3S15 4.34 15 6V8H9V6M12 17C10.9 17 10 16.11 10 15S10.9 13 12 13C13.1 13 14 13.89 14 15C14 16.11 13.11 17 12 17M23 18V20H20V23H18V20H15V18H18V15H20V18H23Z" />
                          </svg>
                          </span>
                          <input type="password"  onChange={handlepassword_otp} placeholder="Enter OTP *"/>
                        </div> :''}
                        
                        <ReCAPTCHA
                        className='m-1'
                        sitekey={SITE_KEY}
                        onChange={handler}
                        />
                        <div className="input_field_col mt-2">
                        {flag  ? 

                    flag1 ?<button className='login' onClick={verifyOtp}>Submit</button>:<button className='login ' onClick={login}>GET OTP</button>:
                    flag1 ?<button className='login' disabled onClick={verifyOtp}>Submit</button>:<button className='login ' disabled onClick={login}>GET OTP</button>
                        }
                        
                        </div>
                      
                        
                        </div>
}
                    </div>
                  
                </form>
               
                <div className="or_devider"><span>OR</span></div>
                
                <ul className="social_connect">
                    <li><a href="/" onClick={handleFacebookSignIn}><span><img src={facebook} alt=""/></span> Facebook</a></li>
                    <li><a href="/" onClick={googleSignIn}><span><img src={google} alt=""/></span> Google</a></li>
                </ul>
            </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Login