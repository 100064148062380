import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Login from './components/Login';
import Header from './components/Header';
import Register from './components/Register';
import Protected from './services/Protected';
import Redirect from './services/Redirect';
import Getuserdetails from './components/Userconfig/Getuserdetails';
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Contentdetails from './components/Contentdetails';
import Listpackage from './components/Listpackage';
import Local from './services/Local';
import Updateprofile from './components/Userconfig/Updateprofile';
import Privacypolicy from './components/Details/Privacypolicy';
import Termsandcondition from './components/Details/Termsandcondition';
import Refundpolicy from './components/Details/Refundpolicy';
import Aboutus from './components/Details/Aboutus';
import Resetpassword from './components/Userconfig/Resetpassword';
import Changepassword from './components/Userconfig/Changepassword';
import Contactus from './components/Details/Contactus';
import Categories from './components/Category/Categories';
import Listpaymentgateway from './components/Gateway/Listpaymentgateway';
import Allpackages from './components/Allpackages';
import Success from './components/Gateway/Success';
import ExampleComponent from './components/Recaptcha/ExampleComponent';
import Success_paytm from './components/Gateway/Success_paytm';
import Activateemail from './components/Userconfig/Activateemail';
import Upcomingcontentdetails from './components/Upcomingcontentdeatils';
import ReactPlay from './Player/Reactplay';
import Test from './Player/Test';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserAuthContextProvider>
  <Router>
    <Routes>
      {/*root routing */}
      <Route path="/" element={<Redirect Protect={App}/>}/>
      {/* <Route path="/recap" element={<ExampleComponent/>}/> */}
     <Route path="/login" element={<Redirect Protect={Login}/>}/>
    {/*  <Route path="/login/:message" element={<Redirect Protect={Login}/>}/>
      {/* <Route path="/register" element={<Redirect Protect={Register}/>}/> */}
      {/* <Route path="/content/details/:contentId" element={<Local Protect={Contentdetails}/>}/> */}
      {/* <Route path="/upcoming/content/details/:contentId" element={<Local Protect={Upcomingcontentdetails}/>}/> */}
      {/* <Route path="/subscriptions/plans/:userId" element={<Protected Protect={Listpackage}/>}/> */}
      {/* <Route path="/subscriptions/plans/" element={<Local Protect={Allpackages}/>}/> */}
      {/* <Route path="/subscriptions/payments/modes/active/:packageId" element={<Protected Protect={Listpaymentgateway}/>}/> */}
      {/* <Route path="/subscriptions/payments/success/:paymentId/:orderId" element={<Protected Protect={Success}/>}/> */}
      {/* <Route path="/subscriptions/:payments" element={<Protected Protect={Success_paytm}/>}/> */}

               {/*<Route path="/subscriptions/payments/checkout/:packageId/:paymentgatewayName/:paymentGatewayId" element={<Local Protect={Stripe_gateway}/>}/>*/}

      <Route path="/privacypolicy" element={<Local Protect={Privacypolicy}/>}/>
      <Route path="/termsconditions" element={<Local Protect={Termsandcondition}/>}/>
      <Route path="/refundpolicy" element={<Local Protect={Refundpolicy}/>}/>
      <Route path="/aboutus" element={<Local Protect={Aboutus}/>}/>
      <Route path="/contactus" element={<Local Protect={Contactus}/>}/>
      {/* <Route path="/categories/:categoryName/:categoryId" element={<Local Protect={Categories}/>}/> */}
      {/* <Route path="/my-profile/:userId" element={<Protected Protect={Getuserdetails}/>}/> */}
      {/* <Route path="/my-profile/update/:userId" element={<Protected Protect={Updateprofile}/>}/>
      <Route path="/resetPassword/:resetpassword" element={<Local Protect={Resetpassword}/>}/>
      <Route path="/my-profile/update/resetpassword/:userId" element={<Local Protect={Changepassword}/>}/>
      <Route path="/capture/paytm/callback" element={<Protected Protect={Success}/>}/>      
      <Route path="/activateEmail/:activateemail" element={<Local Protect={Activateemail}/>}/>      
      <Route path="/trailer/play/:title/:id" element={<Local Protect={ReactPlay}/>}/>   
      <Route path="/upcoming/trailer/play/:title/:id" element={<Local Protect={ReactPlay}/>}/>   
      <Route path="/episode/play/:title/:id" element={<Protected Protect={ReactPlay}/>}/>    
      <Route path="/video" element={<Test/>}/>    */}
    </Routes>
  </Router>
  </UserAuthContextProvider>
)

export const loginrouter =()=>{
  return(
    <>
    <Header/>
    <Login/>
    </>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
