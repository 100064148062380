import React,{useState,useEffect} from 'react'
import '../../App.css';
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { useUserAuth } from '../../context/UserAuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;
const Contactus = () => {
    const [number,setnumber]=useState('')
    const [name,setname]=useState('')
    const [email,setemail]=useState('')
    const [description,setdescription]=useState('')
    const [selectedOption,setselectedOption]=useState({value:'Subject*'})
    const [message,setmessage]=useState('')
    const [flag,setflag]=useState(false)
    const { Support , verify} = useUserAuth();
    const contact=async (e)=>{
        e.preventDefault();
        const data = {
            "mobile": number,
            "issueType" : selectedOption.value,
            "email": email,
            "name":name,
            "description":description
        }
        console.log(data)
        const result = await Support(data)
        console.log(result)
        setmessage(result.message)
    }
    const handleselect=(e)=>{
        setselectedOption({value:e.target.value})
    }
    const handler=async (e)=>{
        console.log(e)
        if(e === null){
            setflag(false)
            //setflagind(true)
            //setui_msg('please check recaptcha verfication, session expired !!')
        }else{
        const result = await verify(e);
        console.log(result)
        if(result.data.data.success){
            setflag(true)
            //setui_msg('')  
          }
        }
    }
  return (
    <div className='container contactus mt-0'>
        <div className='row'>
            <div className='col-md-4'>
               
                <h1><span>CONTACT</span> US</h1>
                <p>Reach out to us for any queries, suggestions or feedback!</p>
                <div className='company mt-2 p-0'>
                <h2>Firangiflix Pvt Ltd</h2>
                <div className='row'>
                    <div className='col-md-2'>
                        <p>Email </p>
                    </div>
                    <div className='col-md-1'><p>:</p></div>
                    <div className='col-md-9'>
                        <p>chaskahechaska@gmail.com</p>
                    </div>
                </div>
                <div className='row mt-0'>
                    <div className='col-md-2'>
                        <p>Contact</p>
                    </div>
                    <div className='col-md-1'><p>:</p></div>
                    <div className='col-md-9'>
                        <p>+91-8850529983</p>
                    </div>
                </div>
                <div className='row mt-0'>
                    <div className='col-md-2'>
                        <p>Address</p>
                    </div>
                    <div className='col-md-1'><p>:</p></div>
                    <div className='col-md-9' >
                        <p>158/1259A, 1st Floor, Rd Number 3, Sahayog CHS LTD, Prarthana Society, Motilal Nagar I, Goregaon West, Mumbai, Maharashtra 400104</p>
                    </div>
                </div>
                </div>
                
            </div>
            <div className='col-md-8'>
            <p style={{'fontSize':'20px' ,'marginBottom':'20px','color':'orange'}}>{message}</p>
                <form onSubmit={contact}>
                    <div className="mb-3">
                        <input type="text" placeholder='Full Name*' onChange={(e)=> setname(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-4">
                        <input type="email" placeholder='Email Address*'  onChange={(e)=> setemail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-4">
                    <PhoneInput
                            required
                            className='phone rounded'
                              defaultCountry="IN"
                              value={number}
                              onChange={setnumber}
                              maxLength='11'
                              placeholder="Enter Phone Number *"    
                          />
                    </div>
                     
                    <div className="mb-3">
                    <select onChange={handleselect} >
                        <option value="Payment Issues">Payment Issues</option>
                        <option value="Subscription Issues">Subscription Issues</option>
                        <option value="Video Plaback Issues">Video Plaback Issues</option>
                        <option value="Business Purpose">Business Purpose</option>
                        <option value="Other Issues">Other Issues</option>
                    </select>
                    </div>
                    <div className="mb-3">
                        <textarea placeholder='Tell us more about your issue*'  onChange={(e)=> setdescription(e.target.value)} rows="6"/>
                    </div>
                    <ReCAPTCHA
                        className='m-1'
                        sitekey={SITE_KEY}
                        onChange={handler}
                        />

                    {flag ? 
                    <button type="submit" className="btn login p-3" style={{'backgroundColor':'#009BFF','color':'#fff','float':'right'}}>Send Message</button>
                    :
                    <button type="submit" className="btn login p-3" style={{'backgroundColor':'#009BFF','color':'#fff','float':'right'}} disabled>Send Message</button>
                    }                    
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contactus