import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCqdtzf0JgA4UOqNwzoESJKl3-c636I5Dw",
  authDomain: "pointmedia-d893b.firebaseapp.com",
  projectId: "pointmedia-d893b",
  storageBucket: "pointmedia-d893b.appspot.com",
  messagingSenderId: "341900626340",
  appId: "1:341900626340:web:424e882d9a1de0e63ad931",
  measurementId: "G-KTSE1497CG"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth= getAuth(app);
//export default app;