import React, { useEffect, useState } from 'react'
import '../App.css';
import { useParams } from 'react-router-dom';
import logo from '../assets/logo/chaska.png';
import search from "../assets/image/search_icon.png";
import {useNavigate,Link} from 'react-router-dom';
import menubar from "../assets/image/mobile_menu_icon.png";
import closemenu from '../assets/image/mobile_menu_close_icon.png';
import { useUserAuth } from '../context/UserAuthContext'
const static_url = process.env.REACT_APP_CDN_STATIC;
const Header = (props) => {
    let navigate = useNavigate()
    const [state_google,setgoogle]=useState(false)
    const [collection ,setcollection]=useState([])
    const [categories,setcategories]=useState([])
    const [menu_state,setmenustate]=useState(true)
    const [category,setcategory]=useState(true)
    const [input,setinput]=useState('')
    const [subs ,setSubs]=useState(false)
    const [userid,setuserid]=useState('')
    const flag_sub = localStorage.getItem('flag')
    //importing function 
    const { logout, allCategories,allContents , allSubscriptions} = useUserAuth();

    //for widgets
    const closemenubar=()=>{
        setmenustate(!menu_state)
    }

    useEffect(()=>{ 
        const id = localStorage.getItem('id')  
        if(!id){
            setSubs(true)
        }else{
            setuserid(id)
            setSubs(false)
        }
        if(allSubscriptions.length > 0){
            //if(allSubscriptions[0].subscription.length === 0 ){
                //setst(true)
            //  }
        }    
    },[])

    const filter_result=(data, key)=>{
        
        const title = data.filter(x=>x.test.title.toLowerCase().includes(key.toLowerCase()))
        if(key.length <= 0){
            setcollection([])
        }else{
            setcollection(title)
        }
        
    }
    const handlesearch=(e)=>{
        const val = e.target.value
        setinput(val)
        filter_result(allContents,val)
    }
   
const [search_view,setsearch]=useState(false)
    

return (
    <div className='container'>
        <header>
        <a href="/" className="logo"><img src={logo} alt=""/></a>
        <div className="right_nav">
       
            <div className='mobile_primary'>
                <img onClick={()=> closemenubar()}  src={menubar}/>
                <ul  className={menu_state ? 'deactive ': 'active'}>
                    <img onClick={()=> closemenubar()} src={closemenu}></img>
                    <li><a href="/"><i className="fa fa-home"></i> Home</a></li>
                    <li onClick={()=>setsearch(!search_view)}><a href="#search"><i className="fa fa-search"></i> Search</a></li>
                    <li><a href="/subscriptions/plans">Subscription</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                </ul>
            </div>
            
            
            <div className={!menu_state ? "header_btn deactive":"header_btn"}>
            { props.state || state_google ? 
                    <ul>
                    <li>
                    <a href={'/my-profile/'+`${userid}`} ><i className="fa fa-user"></i> Account</a>
                    </li>
                    </ul>
                : 
                <ul>
                    <li><a href= "/login"><i className="fa fa-user"></i> Login / Register</a></li> 
                </ul>}
            </div>

            {flag_sub === null ?  
            subs ? 
            <a href="/subscriptions/plans" className="click_to_subsc"><i className="fa fa-rocket"></i> Subscribe</a>:
            <a href={`/subscriptions/plans/${userid}`} className="click_to_subsc"><i className="fa fa-rocket"></i> Subscribe</a>
            :''}

            <div className="header_search">
                <div className="toggle_search" onClick={()=>setsearch(!search_view)}>
                    <span><img src={search} alt=""/></span>Search
                </div>
                <div className={search_view ? 'toggle_search_open' :'open toggle_search_open'}>
                    <div className="container">
                        <div className="toggle_search_open_area">
                            <div className="toggle_close" onClick={()=> setsearch(!search_view)}></div>
                            <input type="text" placeholder="Search" value={input} onChange={handlesearch} name=""/>
                            <input type="submit" name=""/>
                        </div>

                        {/*for search result */}
                        <section className="product">
                            <div className="container">
                                <div className="product_category">
    
                                    <div className="product_item_list">
                                    {
                                    collection.map( (y,index) => {
                                        return(
                                               
                                                        <a  key={index}  href={'/content/details/'+`${y.test.awsStaticResourcePath}`+'/'+`${y.test._id}`} className="product_item">
                                                            <div className="product_item_image">
                                                                <img src={static_url+'/'+y.test.awsStaticResourcePath +'/'+y.test.portraitPosterIdNormal} alt=""/>
                                                                <div className="product_item_image_overlay.test">
                                                                    <div className="play_icon"></div>
                                                                </div>
                                                            </div>
                                                            <p>{y.test.title}</p>
                                                        </a>
                                                  
                                              
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
          {/* 
           <span className="click_to_category"  onClick={()=> setcategory(!category)}
         /> Category</span> */}


            <a href="/" className="click_to_subsc"> Home</a>
        </div>

        <div className={category ? 'category_block':'category_block active'}>
            <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                {categories.map( (x,index) => {
                    return (<div key={index}>
                    {(x.category.map((y,index)=>{
                            return(
                                <div key={index} className='category_options'><a className='co' href={'/categories/'+ y.title + '/'+ y._id}>{y.title}</a></div>
                            )
                        }))}
                        </div>)
            })}
                  
                </div>
                <div className='col-md-6'>
                    <li>Watch </li>
                    <li>Company </li>
                    <li>Privacy </li>
                    <li>Downloads </li>
                    <li>Refunds </li>
                </div>
            </div>
            </div>
            
           
        </div>
        </header>
    </div>
  )
}

export default Header