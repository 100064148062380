import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const apiKEY=process.env.REACT_APP_API_KEY;
var token =localStorage.getItem('access-token') ; 
const Access = axios.create({
    baseURL: baseURL,
    headers:{
    "Content-Type": "application/json",
    "signature":"kWVK7yyA9i7NWo9tJpl6ZpZYvsk=",
    "accesstoken": token
    }
});

export default Access;
