import React from 'react'
import footer from '../assets/logo/chaska.png';
import app1 from "../assets/image/download_app_1.png";
import app2 from "../assets/image/download_app_2.png";
import '../App.css';
const Footer = () => {
  return (
    <footer>
    <div className="container">
        <div className="row footer_area p-4">
            <div className="col-md-3">
                <a href="/" className="footer_logo"><img src={footer} style={{'width':'200px'}} alt=""/></a>
                <div className="footer_download">
                    <h3>Download Our App</h3>
                    <a href="/"><img src={app1} alt=""/></a>
                    <a href="/" className='mt-2'><img src={app2} alt=""/></a>
                </div>
            </div>
            <div className="col-md-3">
                <h2>Quick Links</h2>
                <ul className="quick_link">
                    <li className="active"><a href="/">Home</a></li>
                    <li><a href="/contactus">Contact Us</a></li>
                    <li><a href="/aboutUs">About Us</a></li>
                    <li><a href="/subscriptions/plans/">Subscription</a></li>
                    <li><a href="/privacypolicy">Privacy Policy</a></li>
                    <li><a href="/termsconditions">Terms  Conditions</a></li>
                    <li><a href="/refundpolicy">Refund Policy</a></li>
                </ul>
            </div>
            <div className="col-md-6">
                <h2>Get In Touch</h2>
                <div className="newsletter_form">
                    <input type="text" placeholder="Enter Your Email Address" name=""/>
                    <input type="submit" value="SUBSCRIBE" name=""/>
                </div>
            </div>
        </div>
        <div className="last_footer">
            <p>© 2024 All Rights Reserved to <span><b>Firangiflix Pvt Ltd</b></span> v01.02</p>
        </div>
    </div>
</footer>
  )
}

export default Footer


 
