import React,{useEffect,useState,useRef} from 'react';
import logo from "../assets/logo/chaska.png"
import why1 from "../assets/image/why_choose_icon_1.png"
import why2 from "../assets/image/why_choose_icon_2.png"
import why3 from "../assets/image/why_choose_icon_3.png"
import why4 from "../assets/image/why_choose_icon_4.png"
import homeback from '../assets/image/homescreen.jpg'
import { useUserAuth } from '../context/UserAuthContext'
import Install from './Contents/Install';
const static_url = process.env.REACT_APP_CDN_STATIC;
const Home = ({props}) => {
const {fetch,allContents,AddData,allPosters,allUpcomings} = useUserAuth()
const scrl = useRef()
const scrl1=useRef()

useEffect(() => {
    console.log(allPosters)
    console.log(allUpcomings)
    console.log(allContents.length)
    //console.log(testContents) //[_id  , test:{}]
    //checking for update 
    fetch()    
   // hit()
}, [])

const hit=()=>{
    if(allContents.length > 0){
        callback(allContents)
    }
    else{
        AddData()
    }
}


const movies = []
const arr = []
const [Content,setContent]  =useState([])
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
   }

const callback=(data)=>{
    data.map(x => x.test.subcategory.map(x=> movies.push(x)))
    //data.content.map(x => x.subcategory.map(x=> movies.push(x) ))
    const uniq = movies.filter(onlyUnique)
    for(var i=0;i<uniq.length;i++){
        //only checking first value of an subcategory array for filteration
        const result = data.filter(x=> x.test.subcategory[0] === uniq[i]  )
        var key = uniq[i]
        arr.push({name: key ,value:result})
    }
    //console.log(arr)
    setContent(arr)
}


//sliding buttons
const slide = (shift) => {
scrl.current.scrollLeft += shift;
};
const slide1 = (shift) => {
scrl1.current.scrollLeft += shift;
};
  
  
return (
<>  
{/* <section className="banner">
    <div className="banner_col">
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
   
    <div className="carousel-inner">
        <div className="carousel-item active">
        <img src={homeback}/>
        </div>
        {allPosters.map(x=>{
            if(x.poster.link === 'https://'){
                return (
                    <div className="carousel-item">
                    <img src={static_url +'/' +x.poster.awsResourceId+'/'+x.poster.url}/>
                    </div>
                )
            }else{
                return(
                    <div className="carousel-item">
                    <img src={static_url +'/' +x.poster.awsResourceId+'/'+x.poster.url}/>
                    </div>
                )
            }
            
        })}
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
    </button>
    </div>

    </div>

    <div className="banner_overlay">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-content">
                        <div className="homelogo">
                            <img src={logo} alt="" width="120px" height="auto"/>
                        </div>
                        <h1>Watch Unlimited Movies, Music, Web Series Etc. Any time any where.</h1>
                        <div className="banner_btn_area">
                            <a href="/subscriptions/plans" className="banner_subsc_btn"><span>
                                <img src="assets/image/banner_subsc_btn_icon.png" alt=""/></span>Subscribe</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>

</section>

<section className="why_choose">
    <div className="container">
        <div className="sec_heading"><h2>Why choose Chaska?</h2></div>
        <div className="why_choose_area">
            <ul>
                <li>
                    <div className="why_choose_icon"><img src={why1} alt=""/></div>
                    <h3>Subscribe At a Low <br/> Price.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why2} alt=""/></div>
                    <h3>Watch on any <br/> device.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why3} alt=""/></div>
                    <h3>Download now. Watch <br/> later.</h3>
                </li>
                <li>
                    <div className="why_choose_icon"><img src={why4} alt=""/></div>
                    <h3>New entertainment, every <br/> week.</h3>
                </li>
            </ul>
        </div>
    </div>
</section>


{
    Content.map((x,index)=>{
        return(
            
            <section className="product" key={index}>
            <div className="container">
                <div className="product_category">
                    <div className="product_category_heading">
                    <h4>{x.name}</h4>
                    </div>
                    <div className="product_item_list" ref={scrl}>
                        { x.value.map((y,index)=>{
                            return(
                                <div key={index}>
                                <a  key={index}  href={'/content/details/'+`${y.test._id}`} className="product_item">
                                            <div className="product_item_image">
                                                <img src={static_url+'/'+y.test.awsStaticResourcePath +'/'+y.test.portraitPosterIdNormal} alt=""/>
                                                <div className="product_item_image_overlay">
                                                    <div className="play_icon"></div>
                                                </div>
                                            </div>
                                            <p>{y.test.title}</p>
                                        </a>
                                </div>
                            )
                        })}
                    
                    </div>
                </div>
            </div>
        </section>
            
        )
    }) 
}*/}

                  
{/*all contentn mapping */}
{/* <section className="product">
    <div className="container">
        <div className="product_category">
            <div className="product_category_heading">
                <h4>Chaska Originals</h4>               
            </div>
            <div className="product_item_list" ref={scrl}>
            {
            allContents.map( (x,index) => {
                return(
                        <div key={index}>
                       
                                <a href={'/content/details/'+`${x.test._id}`} className="product_item">
                                    <div className="product_item_image">
                                        <img src={static_url+'/'+x.test.awsStaticResourcePath +'/'+x.test.portraitPosterIdNormal} alt=""/>
                                        <div className="product_item_image_overlax.test">
                                            <div className="plax.test_icon"></div>
                                        </div>
                                    </div>
                                    <p>{x.test.title}</p>
                                </a>
                           
                        </div>
                )
            })}
            </div>
         </div>
    </div>
</section> */}

{/* <section className="product">
    <div className="container">
        <div className="product_category">
            <div className="product_category_heading">
                <h4>Upcoming Contents</h4>
                <span><i className='fa fa-arrow-left' onClick={() => slide1(-280)}></i><i className='fa fa-arrow-right' onClick={() => slide1(+280)}></i></span>
            </div>
            <div className="product_item_list" ref={scrl1}>
            {
            allUpcomings.map( (x,index) => {
                            return(
                                <a  key={index} href="#" className="product_item">
                                    <div className="product_item_image">
                                        <img src={static_url+'/'+x.test.resourcePath +'/'+x.test.landscapeNormal} alt=""/>
                                        <div className="product_item_image_overlay">
                                            <div className="play_icon"></div>
                                        </div>
                                    </div>
                                    <p>{x.test.title}</p>

                                </a>
                            )
            })}
            </div>
         </div>
    </div>
</section>
<div className='deep_handle'>
<Install/>
</div> */}
</>
)
}

export default Home